import React from "react"
import tw, {
  styled,
  css
} from "twin.macro"
//eslint-disable-next-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../misc/Headings.js"
import {
  SectionDescription
} from "../../misc/Typography.js"

import defaultCardImage from "../../images/shield-icon.svg"
import SupportIconImage from "../../images/support-icon.svg"
import ShieldIconImage from "../../images/shield-icon.svg"
import CustomizeIconImage from "../../images/customize-icon.svg"
import FastIconImage from "../../images/fast-icon.svg"
import ReliableIconImage from "../../images/reliable-icon.svg"
import SimpleIconImage from "../../images/simple-icon.svg"

const Container = tw.div `relative -mx-8 px-8 text-gray-900`

const ThreeColumnContainer = styled.div `
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`
const Subheading = tw(SubheadingBase)
`mb-4 text-gray-100`
const Heading = tw(SectionHeading)
`w-full`
const Description = tw(SectionDescription)
`w-full text-center text-gray-600`

const VerticalSpacer = tw.div `mt-10 w-full`

const Column = styled.div `
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`

const Card = styled.div `
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6 text-primary-800 `}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-600 leading-snug`}
  }
`

function Features({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = "Our easier to navigate application gives a variety of customizable functionalities to address the needs of farmers and consumers",
}) {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [{
      imageSrc: ShieldIconImage,
      title: "Digital",
      description: "JUT digitalizes the farmers market by uniquely connecting consumer and farmers through farmers market and beyond.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Extensible",
      description: "JUT app is built on serverless architecture and designed to expand to multiple farmers market across the country and beyond."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Easy to use",
      description: "Customers can search and order the required produce from various local farmers from a simple to use mobile app."
    },
    {
      imageSrc: ReliableIconImage,
      title: "Notifications",
      description: "Our application is capable of notifying the farmer when they receive an order and notify the customer on various stages of the order processing until it is ready for pick up"
    },
    {
      imageSrc: FastIconImage,
      title: "Enable social distancing",
      description: "Advance ordering will help customers to reduce their shopping time and help them in social distancing when required. "
    },
    {
      imageSrc: SimpleIconImage,
      title: "Farmer Dashboard",
      description: "Enable farmers to view their current order, current inventory that provides various insights to their demand forecasting. This will help farmer to harvest the right amount to reduce food wastage"
    },
  ]

  if (!cards) cards = defaultCards

  return ( <
    Container >
    <
    ThreeColumnContainer > {
      subheading && < Subheading > {
        subheading
      } < /Subheading>} <
      Heading > {
        heading
      } < /Heading> {
      description && < Description > {
        description
      } < /Description>} <
      VerticalSpacer / > {
        cards.map((card, i) => ( <
          Column key = {
            i
          } >
          <
          Card >
          <
          span className = "imageContainer" >
          <
          img src = {
            card.imageSrc || defaultCardImage
          }
          alt = "" / >
          <
          /span> <
          span className = "textContainer" >
          <
          span className = "title" > {
            card.title || "Fully Secure"
          } < /span> <
          p className = "description" > {
            card.description ||
            "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."
          } <
          /p> < /
          span > <
          /Card> < /
          Column >
        ))
      } <
      /ThreeColumnContainer> < /
      Container >
    )
  }

  export default Features