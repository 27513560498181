import React from "react"
import tw, {
  css
} from "twin.macro"

import {
  Container as ContainerBase,
  ContentWithVerticalPadding,
  Content2Xl,
} from "../../misc/Layouts.js"
import {
  SectionHeading
} from "../../misc/Headings.js"
import {
  SectionDescription
} from "../../misc/Typography.js"
import {
  PrimaryButton as PrimaryButtonBase
} from "../../misc/Buttons.js"
import serverIllustrationImageSrc from "../../images/hero1.png"

const PrimaryBackgroundContainer = tw.div `-mx-8 px-8 text-primary-600`

const Container = tw(ContainerBase)
``
const Row = tw.div `flex items-center flex-col lg:flex-row`
const Column = tw.div `lg:w-1/2`
const TextColumn = tw.div `text-center lg:text-left`
const IllustrationColumn = tw(Column)
`mt-16 lg:mt-0 lg:ml-16`
const Heading = tw(
  SectionHeading
)
`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`
const Description = tw(
  SectionDescription
)
`mt-4 max-w-2xl text-gray-600 lg:text-base mx-auto lg:mx-0`
const PrimaryButton = tw(
  PrimaryButtonBase
)
`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`
const Image = tw.img ` ml-auto`

export default ({
  heading = "Your Local Farmers Market Digitally in Your Hands",
  description = "Our mobile app is uniquely positioned to connect farmers market to consumers by keeping the local connect but increasing the chance of social distancing and reducing the food wastage by pre-planning",
  primaryButtonText = "Download and Start exploring",
  primaryButtonUrl = "/download",
  imageSrc = serverIllustrationImageSrc,
}) => {
  return ( <
    PrimaryBackgroundContainer >
    <
    Content2Xl >
    <
    Container >
    <
    >
    <
    Row >
    <
    TextColumn >
    <
    Heading > {
      heading
    } < /Heading> <
    Description > {
      description
    } < /Description> <
    PrimaryButton as = "a"
    href = {
      primaryButtonUrl
    } > {
      primaryButtonText
    } <
    /PrimaryButton> <
    /TextColumn> <
    IllustrationColumn >
    <
    Image src = {
      imageSrc
    }
    /> <
    /IllustrationColumn> <
    /Row> <
    /> <
    /Container> <
    /Content2Xl> <
    /PrimaryBackgroundContainer>
  )
}